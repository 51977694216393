.settings {
    font-size: 15px;
    font-family: Avenir Next;
    /* font-weight: 400; */
    min-height: 500px;
}

.settings-title {
    font-weight: 500;
    font-size: 19px;
    color: #000000;
}

.settings-item {
    padding: 8px 0px;
    font-size: 16px;
    row-gap: 9px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: flex-start;
}

.settings-item-title {
    font-weight: 600;
    font-size: 12px;
    color: #8b8b8b;
    text-transform: uppercase;
    font-family: "Avenir Next";
}

.user-avatar {
    font-weight: 500;
    justify-content: center;
    height: 80px;
    width: 80px;
    border-radius: 50%;
    font-size: 25px;
    align-items: center;
    color: #ffffff;
    display: flex;
    background-color: #ffa799;
    background-image:
        radial-gradient(at 75% 31%, hsla(312, 60%, 77%, 1) 0px, transparent 50%),
        radial-gradient(at 13% 77%, hsla(256, 65%, 61%, 1) 0px, transparent 50%),
        radial-gradient(at 61% 26%, hsla(200, 91%, 63%, 1) 0px, transparent 50%);
}

.thin-button {
    margin-left: 10px;
    font-size: 13px;
    font-weight: 500;
    color: #640FF5;
    cursor: pointer;
}

.thin-button:hover {
    color: black;
}


.settings-item.same-line {
    display: flex;
    align-items: center;
}

.sl {
    display: flex;
    align-items: center;
    column-gap: 8px;
}

.ml {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.settings-item-input {
    border: 1px solid #cacaca;
    min-width: 250px;
    background: #ffffff;
    padding: 7px 13px;
    /* width: 100%; */
    border-radius: 9px;
    font-weight: 500;
    /* font-size: 16px; */
}

.settings-item-input:hover {
    border: 1px solid #000000;
}

.settings-item-input.disabled {
    background-color: #ededed;
    color: #979797;
}

.log-out-button {
    /* font-size: 13px; */
    font-weight: 500;
    color: #640FF5;
    cursor: pointer;
    margin-top: 15px;
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-left: 13px;
}

.log-out-icon {
    height: 20px;
    margin-right: 5px;
}

.log-out-button:hover {
    color: black;
}

.button {
    font-size: 13px;
    font-weight: 500;
    color: #404040;
    cursor: pointer;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 5px 11px;
    display: inline-block;
    border: 1px solid #d5d5d5;
}

.button:hover {
    color: #9100c1;
    background-color: #fcf6ff;
    border-color: #d3b2ff;
}

.button.disabled {
    color: #979797;
    background-color: #ededed;
    border-color: #d5d5d5;
    cursor: not-allowed;
}

.back-button {
    color: #640FF5;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 15px;
    margin-bottom: 10px;
}

.back-button-icon {
    height: 20px;
    margin-right: 5px;
}

.back-button:hover {
    color: #000000;
}


.settings-container {
    display: flex;
    flex-direction: row;
    align-items: top;
    /* padding: 20px; */
    border-radius: 10px;
}

.settings-sidebar {
    width: 300px;
    padding: 20px 13px;
    display: flex;
    flex-direction: column;
    background-color: rgb(251, 251, 251);
    border-right: 1px solid rgb(238, 238, 238);
    /* width: 320px; */
    /* min-width: 320px; */
    /* max-width: 320px; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-bottom: 100px;
}

.settings-sidebar-item {
    font-size: 13px;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #303030;
    padding: 7px 13px;
    border-radius: 4px;
    /* margin-left: -14px; */
    width: 100%;
    margin-bottom: 4px;
}

.settings-sidebar-item:hover {
    color: #000000;
    background-color: rgb(242, 242, 242);
}

.settings-sidebar-item.active {
    color: #000000;
    background-color: rgb(239 239 239);
}

.settings-sidebar-icon {
    height: 18px;
    margin-right: 5px;
    color: #a7a7a7;
}

.settings-sidebar-item.active .settings-sidebar-icon {
    color: #000000;
}

.settings-content {
    width: calc(100% - 300px);
}

.settings-item-value {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.settings-rowdivider {
    margin: 12px 0;
    border-style: none;
    width: 100%;
    height: 1px;
    background-color: #dcdce0;
}

.settings-row {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    transition: opacity 0.2s ease-in-out;
    width: 100%;
}


.row-left {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    display: flex;
    color: #212121;
}

.row-left-top {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    font-family: inherit;
    align-items: center;
    display: flex;
    margin-bottom: auto;
}

.row-left-bottom {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    font-family: inherit;
    margin-top: 2px;
}

.row-right {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    font-family: inherit;
    margin-top: 2px;
}

.settings-item-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #640FF5;
    font-weight: 500;
}

.settings-item-link-icon {
    height: 18px;
    margin-left: 5px;
}

@media only screen and (max-width: 768px) {

    .mobile-settings-page {
        padding: 14px;
        /* border-radius: 5px; */
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .settings-container {
        /* display: block; */
        display: inline;
        margin: 0px;
    }

    .account-page {
        display: flex;
        flex-direction: column;
    }

    .account-page-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .settings-avatar {
        border-radius: 50%;
        height: 75px;
    }

    .settings-name {
        font-size: 20px;
        font-weight: 500;
    }

    .account-page-table {
        border-radius: 10px;
        padding: 3px 5px 3px 20px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
    }

    .account-page-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        /* margin-bottom: 10px; */
        padding: 15px 0px;
        border-bottom: 1px solid #ededed;
    }

    .account-page-meta {
        display: flex;
        flex-direction: column;
    }

    .account-page-cell-icon {
        height: 40px;
        margin-right: 5px;
        padding: 5px;
        background-color: #000000;
        border-radius: 6px;
        color: #ffffff;
        margin-right: 12px;
    }

    .account-page-cell-left {
        display: flex;
        align-items: center;
        flex: 1 1 auto;
    }


    .chevron-right-icon {
        height: 35px;
        color: #dddddd;
    }

    .account-page-cell-title {
        font-weight: 500;
        color: #000000;
        font-size: 17px;
        line-height: 22px;
    }

    .account-page-cell-description {
        font-weight: 500;
        color: #9b9b9b;
        font-size: 14px;
    }

    .account-nav-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;

        font-weight: 500;
        font-size: 18px;
    }

    .settings-item {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        justify-content: space-between;
        padding: 15px 10px 15px 20px;
        align-items: center;
        font-size: 18px;
    }

    .thin-button {
        font-size: 18px;
    }

    .settings-item.same-line {
        background-color: transparent;
        justify-content: initial;
        padding: 10px;
    }

    .settings-item-title {
        margin-bottom: 0px;
        font-size: 18px;
    }

    .settings-item-input {
        border: none;
        text-align: right;
    }

    .personal .settings-item {
        flex-direction: column;
        align-items: baseline;
    }

    .personal .settings-item-title {
        margin-bottom: 5px;
        font-size: 18px;
    }
}

.add-calendar-container {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #e7e7e7;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.add-calendar-container:hover {
    border: 2px solid #640FF5;
}

.add-calendar-text {
    font-size: 14px;
    font-weight: 500;
    color: #212121;
    display: flex;
    align-items: center;
}

.gcal-icon {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.disconnect-button {
    background-color: #ffffff;
    border: 2px solid #f4f4f4;
    cursor: pointer;
    padding: 11px 13px;
    color: #ff1f1f;
    border: 1px solid #ff9a9a;
    padding: 5px 11px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 500;
}

.add-calendar-container.added {
    background-color: #ffffff;
    border: 2px solid #f4f4f4;
    cursor: default;
    padding: 15px 15px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.add-calendar-text.added {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
}

.settings-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;
    padding: 15px 15px 15px 20px;
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 17px;
    color: black;
}

.close-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #f4f4f4;
    cursor: pointer;
    margin-left: 10px;
}

.close-icon-container:hover {
    background-color: #e7e7e7;
}

.close-icon {
    width: 15px;
    height: 15px;
}

.settings-content .content {
    padding: 15px 30px;
    font-family: Inter;
}

.settings-sidebar-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* margin-bottom: 10px; */
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 17px;
    color: black;
    padding-left: 13px;
    margin-bottom: 4px;
}

.settings-sidebar-header-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Avenir Next";
    font-weight: 600;
    font-size: 17px;
    color: black;
}

.settings-sidebar-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

.settings-sidebar-avatar-initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    /* mesh gradient background */
    background-color: #ffa799;
    background-image:
        radial-gradient(at 75% 31%, hsla(312, 60%, 77%, 1) 0px, transparent 50%),
        radial-gradient(at 13% 77%, hsla(256, 65%, 61%, 1) 0px, transparent 50%),
        radial-gradient(at 61% 26%, hsla(200, 91%, 63%, 1) 0px, transparent 50%);
}


.settings-sidebar-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.settings-sidebar-header-right-name {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 19px;
}

.settings-sidebar-header-right-email {
    font-size: 14px;
    font-weight: 500;
    color: #9b9b9b;
    line-height: 15px;
}

.settings-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-bottom: 20px; */
    padding: 12px 0px;
}

.settings-section-header {
    /* margin-left: 13px; */
    /* margin-bottom: 7px; */
    text-transform: uppercase;
    font-family: Avenir Next;
    font-weight: 600;
    color: #4c4c4c;
    font-size: 12px;
    padding: 8px 13px 7px 14px;

}

.settings-section-divider {
    width: calc(100% - 18px);
    height: 1px;
    background-color: #e7e7e7;
    margin-left: auto;

    /* margin-right: 12px; */
    margin-right: 5px;
}

.same-line {
    display: flex;
    align-items: center;
    column-gap: 10px;
}