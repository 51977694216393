.date-picker-floating {

    text-align: right;
}

.floating-calendar-button {
    /* background-color: #000000; */
    padding: 8px 16px;
    /* border-radius: 25px; */
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    /* margin-top: 10px; */
}

.floating-calendar-button:hover {
    color: #272EE8;
}


.floating-calendar-button-icon {
    height: 18px;
    margin-right: 8px;
}

.floating-button-chevron {
    margin-left: 8px;
    height: 18px;
}

.date-picker-popover {
    margin-left: 12px;
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
    border: 1px solid #e3e3e3;
}


.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    position: relative;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
    border: 1px solid #e3e3e3;
    /* min-height: 250px; */
}

.react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 16px;
    padding: 0px;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__header {
    text-align: center;
    background-color: #ffffff;
    /* border-bottom: 1px solid #aeaeae; */
    border-top-left-radius: 0.3rem;
    padding: 13px 0 0px 0px;
    position: relative;
    border-bottom: none;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0px;
    margin-bottom: 6px;
    color: #161616;
    font-weight: 500;
    /* font-size: 0.944rem; */
    font-size: 14px;
}

.react-datepicker__day-names .react-datepicker__day-name {
    color: #979797;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
}

.react-datepicker__day--in-range {
    border-radius: 0.3rem;
    background-color: #272EE8 !important;
    color: #fff !important;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--toda {
    font-weight: bold;
    border: 1px solid #272EE8;
    border-radius: 6px;
    color: #272EE8;
}

.react-datepicker__day {
    color: #424242;
    display: inline-block;
    width: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    margin: 0.166rem;
    border: 1px solid transparent;
    font-weight: 500;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #f1f2ff;
    border: 1px solid #004fff;
    color: #000000;
}

.react-datepicker__day--keyboard-selected:hover {
    border-radius: 0.3rem;
    background-color: #f1f2ff;
    border: 1px solid #004fff;
    color: #000000;
}

@media only screen and (max-width: 768px) {
    .react-datepicker {
        font-family: "Helvetica Neue", helvetica, arial, sans-serif;
        font-size: 15px;
        background-color: #fff;
        color: #000;
        display: inline-block;
        position: relative;
        border-radius: 7px;
        background: #FFFFFF;
        border: none;
        /* min-height: 250px; */
        box-shadow: none;
        width: 100%;
    }

    .react-datepicker__month-container {
        width: 100%
    }

    .react-datepicker__navigation--next {
        right: 10px;
    }

    .react-datepicker__navigation--previous {
        left: 10px;
    }

    .react-datepicker__day-names .react-datepicker__day-name {
        color: #979797;
        display: inline-block;
        width: 1.7rem;
        line-height: 1.7rem;
        text-align: center;
        margin: 3%;
    }

    .react-datepicker__day {
        color: #424242;
        display: inline-block;
        width: 1.7rem;
        line-height: 1.7rem;
        text-align: center;
        margin: 3%;
        border: 1px solid transparent;
        font-weight: 500;
        font-size: 18px;
    }

    .react-datepicker__current-month {
        margin-top: 0px;
        margin-bottom: 6px;
        color: #161616;
        font-weight: 500;
        font-size: 18px;
    }
}