.help-icon {
    background-color: #ffffff;
    border: 1px solid #d1d1d1;
    color: #535353;
    /* padding: 5px 13px; */
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 5px;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    justify-content: center;
    font-weight: 600;
}

.help-container {
    margin: -8px -7px;
    border-radius: 10px;
}

.help-section {
    border-bottom: 1px solid #e5e5e5;
    padding: 5px 0px;
}

.help-option {
    padding: 5px 13px;
    display: flex;
    align-items: center;
    /* margin-bottom: 3px; */
    font-weight: 500;
    font-size: 14px;

    color: #444444;
    cursor: pointer;
}

.help-option:hover {
    background-color: #ededed;
    color: #444444;
}

.help-option-icon {
    height: 20px;
}


.help-option-text {
    padding: 2px 5px;
    color: #444444;
}


.light {
    color: #949494;
}

.no-hover {
    background-color: #ffffff;
    cursor: default;
}

.no-hover:hover {
    background-color: #ffffff;
    cursor: default;
}

.Canny_BadgeContainer .Canny_Badge {
    position: absolute;
    top: 11px;
    right: 17px;
    border-radius: 10px;
    background-color: #009fff;
    padding: 5px;
    border: 1px solid white;
    visibility: visible;
    /* left: 10px; */
}