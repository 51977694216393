.analytics {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.chart {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.chart-title {
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
}