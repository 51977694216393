.ellie-menu {
    width: 215px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    box-shadow: 0 1px 7px rgb(0 0 0 / 17%);
    border-radius: 7px;
    font-size: 14px;
    font-weight: 500;
    color: #3e3e3e;
}

.ellie-menu-item {
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    white-space: pre-line;
}

.ellie-menu-item-icon {
    height: 18px;
    width: 22px;
    margin-right: 3px;
    align-self: flex-start;
    color: #808080;
    margin-top: 3px;
    /* background-color: #000000; */
}

.elie-menu-item-text {
    flex: 1;
}

.ellie-menu-item:hover {
    background-color: #f1f1f1;
}


.ant-popover-inner-content {
    padding: 0;
}

.ellie-menu-item-arrow {
    height: 18px;
    width: 22px;
    margin-top: 3px;
}

.ellie-menu-divider {
    width: 100%;
    height: 1px;
    background-color: #3e3e3e;
}

.ellie-menu-item.clickable {
    background-color: #f5f5f5;
    border: 1px solid #dedede;
}

.ellie-menu-item.clickable:hover {
    background-color: #dddddd;
}

.elie-menu-item-subtitle {
    color: gray;
    margin-left: 4px;
}

.ellie-menu-header {
    width: 100%;
    border-bottom: 1px solid #2222222b;
    display: flex;
    justify-content: space-between;
    /* height: 50px; */
    padding: 4px 5px 9px 7px;
    align-items: center;
    flex-direction: row;
    /* height: 43px; */
    /* background-color: #d9d9d9; */
    margin-bottom: 2px;
}

.ellie-menu-header-text {
    font-size: 13px;
    font-weight: 600;
    color: #000000;
}

.ellie-menu-close-icon {
    height: 18px;
    width: 22px;

    color: #cdcdcd;
    /* background-color: #000000; */
    cursor: pointer;
}

.ellie-menu-close-icon:hover {
    color: #000000;
}

.ellie-menu-checkmark {
    height: 18px;
    width: 22px;
    align-self: flex-start;
    color: #808080;
    /* background-color: #000000; */
}