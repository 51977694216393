.labels {
    margin-right: 10px;

}

.labels-title {
    margin-bottom: .25em;
}

.labels-container {
    overflow-y: auto;
    min-width: 150px;
    /* max-height: 120px; */
    flex: 1 1 auto;
    padding: 4px 6px 0px 6px;
}

.label-container {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-radius: 6px;
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 500;
    color: #565656;
}

.label {
    display: flex;
    align-items: center;
}

.label-box {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 6px;
    min-width: 10px;
    min-height: 10px;
}

.card-label span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-container:hover {
    background-color: #f1f1f1;

}

.label-container.selected {
    background-color: #f1f1f1;

}

.label-selected-icon {
    height: 14px;
    color: #ababab;
}

.label-filter-button {
    /* background-color: #000000; */
    padding: 8px 16px;
    /* border-radius: 25px; */
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    display: inline-flex;
    /* margin-top: 10px; */
}

.label-filter-button:hover {
    color: #272EE8;
}


.floating-labels-button-icon {
    height: 18px;
    margin-right: 8px;
}

.floating-button-chevron {
    margin-left: 8px;
    height: 18px;


}

.labels-filter-container {
    background-color: #ffffff;
    border-radius: 6px;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    display: flex;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
    /* max-height: 250px; */
}

/* Updating label styles */

.update-label-container {
    display: flex;
    align-items: center;
    padding: 9px 8px;
    justify-content: space-between;
}

.delete-label-icon {
    height: 19px;
    color: #bbbbbb;
    cursor: pointer;
}

.delete-label-icon:hover {

    color: #000000;

}

.update-label-fields {
    display: flex;
    align-items: center;
    flex: auto;
    margin-right: 10px;
}


.lm-close {
    cursor: pointer;
    height: 20px;
    color: gray;
}

.lm-close:hover {
    color: black;
}

.label-container.edit {
    font-weight: 500;
    color: #640FF5;
    font-size: 13px;
}

.edit-labels-arrow-icon {
    height: 15px;
    margin-left: 4px;
}

@media only screen and (max-width: 768px) {
    .labels-filter-container {
        flex-direction: column;
        display: flex;
        border-radius: 10px;
        background-color: #f3f3f3;
        box-shadow: none;
        /* max-height: 250px; */
        padding: 10px;
        max-height: 100%;
        margin: 10px;
    }

    .labels-container {
        /* max-height: 120px; */
        flex: 1 1 auto;
        padding: 4px 6px 0px 6px;
        overflow-y: visible;
    }

    .label-container {
        font-size: 20px;
    }

    .label-selected-icon {
        height: 22px;
        color: #0084ff;
    }

    .label-container.edit {
        font-weight: 500;
        color: #640FF5;
        font-size: 19px;
    }

}

.label-editable {
    width: 100%;
    height: 40px;
    padding: 0 11px 0 16px;
    box-shadow: inset 0 0 0 1px rgba(188, 188, 201, 0.18);
    column-gap: 16px;
    list-style: none;
    align-items: center;
    border-radius: 7px;
    background-color: #FBFBFC;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    display: flex;
}

.label-editable .meta {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 400;
    color: #323232;
}

.label-editable .action-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border-radius: 5px;
    color: #7b7b7b;
}

.label-editable .action-menu:hover {
    background-color: #f1f1f1;
    color: #000000;
}

.label-editable-color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.label-editable-icon {
    width: 15px;
    height: 15px;
}

.lm-body {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.lm-body .lm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 5px;
}

.lm {
    margin-bottom: 40px;
}

.lm-header .search-bar {
    width: 100%;
    max-width: 300px;
    height: 35px;
    padding: 0 11px 0 13px;
    /* box-shadow: inset 0 0 0 1px rgba(188, 188, 201, 0.18); */
    column-gap: 8px;
    list-style: none;
    align-items: center;
    border-radius: 7px;
    background-color: #ffffff;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: space-between;
    display: flex;
    border: 1px solid #e1e1e1;
    /* color: #000000; */
}

.lm-header .search-bar .search-bar-icon {
    width: 20px;
    height: 20px;
    color: #afafaf;
}

.lm-header .search-bar .search-bar-input {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    color: #323232;
    background-color: transparent;
}

.lm-header .search-bar .search-bar-input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #afafaf;
}

.lm-header .create-button {
    /* width: 200px; */
    height: 35px;
    justify-content: center;
    padding: 10px 17px;
    /* box-shadow: inset 0 0 0 1px rgba(188, 188, 201, 0.18); */
    column-gap: 5px;
    /* list-style: none; */
    align-items: center;
    border-radius: 7px;
    color: #ffffff;
    background-color: #640ef5;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* justify-content: space-between; */
    display: flex;
    cursor: pointer;
    /* background: left; */
    font-size: 13px;
    font-weight: 500;
}

.lm-header .create-button .create-button-icon {
    width: 20px;
    height: 20px;
}

.action-menu-items {
    display: flex;
    align-items: center;
    column-gap: 3px;
}

.label-editable.editing {
    height: 50px;
}