
.day-view {
    padding: 20px;
}


.draggable-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-bottom: 50px;
}

.day-view-header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.date-nav-button {
    height: 25px;
}