.tv-container {
    display: flex;
    flex-direction: column;
}

.tv-top {
    border-bottom: 1px solid #ebebeb;
    padding: 0px 20px 20px 20px;
    display: flex;
    flex-direction: row;
}

.tv-bottom {
    padding: 10px;
}

.task-meta-row {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.task-meta-descriptions {
    display: flex;
    align-items: center;
    font-size: 17px;
    color: #4c4c4c;
    font-weight: 500;
}

.task-meta-row-icon {
    height: 22px;
    margin-right: 8px;
    color: #a7a7a7;
}

.task-meta-arrow {
    height: 21px;
    color: #c5c5c5;
}

.task-description {
    font-size: 17px;
    font-weight: 500;
    margin-left: 10px;
    flex: 1 1 0%;
    outline: none;
}

.tv-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 20px;
    background-color: #252525;
    color: #ffffff;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    font-weight: 500;
}