.card-container {
    padding: 0.75em;
    border-radius: 10px;
    background-color: white;
    border: 1px solid #EFEFEF;
    box-shadow: 0 1px 1px #EFEFEF;
    font-size: .85em;
    transition: opacity .2s;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 10px;
    max-width: 250px;
}

.card-container.activelySelected {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    opacity: 1 !important;
    border: 1px solid #c8d9ff;
}

.card-container.preview {
    border: 2px solid #0071ffa6;
}


/*
.card-container:hover {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
} */

.card-container:focus-within {
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
}

.draggable-card-container {
    padding: 0.25em 0em;
    cursor: grab;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: .5em;
}

.card-description-non-editable {
    appearance: none;
    background-color: initial;
    background-image: none;
    border-radius: 3px;
    border-style: none;
    box-shadow: none;
    color: #172b4d;
    display: block;
    line-height: 20px;
    font-family: "Open Sans";
    outline: none;
    overflow: auto;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 0;
    resize: none;
    transition-duration: 85ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    width: 100%;
    margin-right: 15px;
    padding: 5px;
    border: 2px solid transparent;
}

.card-description {
    appearance: none;
    background-color: initial;
    background-image: none;
    border-radius: 3px;
    border-style: none;
    box-shadow: none;
    color: #172b4d;
    display: block;
    line-height: 20px;
    font-family: "Open Sans";
    outline: none;
    overflow: auto;
    overflow: hidden;
    overflow-wrap: break-word;
    padding: 0;
    resize: none;
    transition-duration: 85ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    width: 100%;
    margin-right: 15px;
    padding: 5px;
    border: 2px solid transparent;
    touch-action: none;
}

.card-description:hover {
    border: 2px dotted #719bff;
    border-radius: 5px;
}

.card-description:focus {
    border: 2px dotted #719bff;
    border-radius: 5px;
}

.card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.card-left-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}


.card-time-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding-top: 5px;
    margin-right: -0.75em;
    margin-left: -0.75em;
    padding-left: 0.75em;
    padding-right: 0.75em;
    border-top: 1px solid #ededed;
}

.card-container.complete {
    opacity: .5;
}

/*
.card-container.complete:hover {
    opacity: 1;
} */

#card-context-menu .ant-popover-inner-content {}

.ant-popover-inner-content {
    border-radius: 5px;
}

.card-context-menu {
    min-width: 150px;
    color: #525252;
    font-weight: 500;
}

.context-menu-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px 15px;

    border-radius: 6px;
}

.context-menu-row:hover {
    color: #000000;
    background-color: #efefef;
}

.context-mmenu-row-icon {
    height: 18px;
    margin-right: 5px;
}


@media only screen and (max-width: 768px) {
    .card-container {
        font-size: 16px;
        padding: 15px;
    }

    .card-header {
        margin-bottom: 10px;
    }
}

.subtask-button {
    /* color: green; */
    height: 15px;
    /* fill: green; */
    width: 15px;
    /* stroke-width: 100px; */
    /* stroke: red; */
    margin-right: 3px;
    color: #9d9d9d;
}

.subtask-button:hover {
    border-color: #2683ff;
    color: #2683ff;
}

.subtask-button-container {
    display: none;
}

.subtask-button-container.visible {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #9d9d9d;
}

.subtask-button-container:hover {
    color: #2683ff;
    font-size: 14px;
}

.card-container:hover .subtask-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #9d9d9d;
    cursor: pointer;
}

.card-container:hover .subtask-button-container:hover {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #2683ff;
    cursor: pointer;
}

.card-drag-preview {
    width: 250px;
    opacity: 0.8;
    cursor: grab;
}

.header-left {
    display: flex;
    flex-direction: column;
}

.task-time {
       /* background-color: #f7f7f7; */
       font-size: 11px;
       padding: 0px 2px;
       border-radius: 5px;
       /* display: inline-flex; */
       font-weight: 600;
       color: #a6a6a6;
}