.card-complete-button {
    border: 2px solid #d3d3d3;
    border-radius: 6px;
    color: #afafaf;
    cursor: pointer;
    width: 23px;
    height: 23px;
    padding: 1px;
}

.card-complete-button.round {
    height: 22px;
    width: 22px;
    border-radius: 50%;
}

.card-complete-button.complete {
    color: #ffffff;
    background-color: #00bb3a;
    border: 0px solid #00bb3a;
}

.card-complete-button.incomplete {
    border: 1.5px solid #d3d3d3;
    color: #afafaf;
}

.card-complete-button.incomplete:hover {
    color: #6ab100;
    border-color: #00bb3a;
}

@media only screen and (max-width: 768px) {
    .card-complete-button {
        border: 2px solid #d3d3d3;
        border-radius: 50%;
        color: #afafaf;
        cursor: pointer;
        height: 25px;
        width: 25px;
        padding: 3px;
    }
}