.column-container {
    margin: 0px 15px;
    display: flex;
    flex-direction: column;
    flex: 0 0 250px;
}

.column-date-container {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.column-date-container.mini {
    margin-bottom: 5px;
}

.column-day-text {
    font-weight: 600;
    font-size: 1.25rem;
    color: #000000;
    margin-right: 5px;
}

.column-date-text {
    font-weight: 600;
    font-size: 1.25rem;
    color: #a7a7a7;
    margin-right: 5px;
}

.draggable-container {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-bottom: 50px;
    overflow-y: auto;
    overflow-x: hidden;
}

.column-header {
    position: sticky;
    top: 0px;
    background: transparent;
    z-index: 100;
    margin-top: 10px;
}

.today-text {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 600;
    color: #1d80ff;
}

@media only screen and (max-width: 768px) {
    .column-day-text {
        font-weight: 600;
        font-size: 25px;
        color: #000000;
        margin-right: 5px;
    }

    .column-date-text {
        font-weight: 600;
        font-size: 25px;
        color: #a7a7a7;
        margin-right: 5px;
    }

    .column-date-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
    }
}