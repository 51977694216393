.react-datepicker__day--selected {
    border-radius: 0.3rem;
    background-color: #077eff;
    color: #ffffff;
}

.react-datepicker__day--today {
    border-radius: 0.3rem;
    color: #000000;
    background-color: #ededed;
}

.react-datepicker__day--selected:hover {
    background-color: #ededed;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time] {
    width: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 3px 7px 3px 10px;
    color: #636363;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}


.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type=time]:hover {
    border: 1px solid #141414;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    /* margin: 5px 0 10px 15px; */
    text-align: left;
    padding: 6px 10px;
    margin: 0px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
    width: 100%;
}

.react-datepicker {
    font-family: "Helvetica Neue", helvetica, arial, sans-serif;
    font-size: 0.8rem;
    background-color: #fff;
    color: #000;
    display: inline-block;
    position: relative;
    border-radius: 7px;
    background: #FFFFFF;
    box-shadow: none;
    border: none;
    /* min-height: 250px; */
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    width: 100%;
    margin-left: 0px;
}