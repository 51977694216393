.label-selector-container {
    display: flex;
    flex-direction: column;

    font-size: 13px;
    box-shadow: 0 3px 6px rgb(0 0 0 / 10%);
    border-radius: 8px;
}

.label-selector-search {
    padding: 7px;
    border-bottom: 2px solid #ebebeb;
    display: flex;
    align-items: center;
}

.label-selector-search-icon {
    height: 17px;
    margin-right: 5px;
    color: #b1b1b1;
}

.label-selector-search-input {
    border: none;
    outline: none;
    font-size: 13px;
}

.label-selector-options {
    padding: 5px 0px;
    max-height: 200px;
    overflow-y: auto;
}

.label-selector-option {
    padding: 7px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.label-selector-option-hashtag {
    margin-right: 5px;
}

.label-selector-option:hover {
    background-color: #f1f1f1;
}

/* Create label styles */

.create-label-container {
    padding: 1em;
}

.label-input-container {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.lable-input-title {
    margin-bottom: .45em;
    font-size: 14px;
}

.label-name-input {
    border-radius: 6px;
    border: 1px solid #cfcfcf;
    padding: 4px 6px;
    width: 100%;
}

.color-picker-container {
    position: absolute;
    z-index: 500;
}

.color-picker-color {
    border-radius: 50%;
    height: 15px;
    width: 15px;
}

.color-picker-color-outer {
    cursor: pointer;
    /* padding: 5px; */
    /* background-color: #000000; */
    border-radius: 8px;
    border: 2px solid #d1d1d1;
    /* box-shadow: inset 0 0 0 1px rgb(188 188 201 / 18%); */
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 1px solid #d3d3d3;
}

.color-picker-color-outer:hover {
    border: 1px solid #424242;
}

.create-label-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.create-label-cancel {
    margin-right: .5em;
    cursor: pointer;
}

.create-label-create {
    padding: 4px 6px;
    background-color: #000000;
    color: #ffffff;
    cursor: pointer;
    border-radius: 6px;
}

.create-label-button-container {
    padding: 7px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #640FF5;
}

.create-label-button-container.selected {
    background-color: #ededed;
}

.create-label-button-container:hover {
    background-color: #f1f1f1;
}

.create-label-icon {
    height: 15px;
    margin-right: 4px;
}

.labels-empty {
    text-align: center;
    padding: 10px;
}

.card-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #4c4c4c;

    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-no-label {
    display: none;
}

.card-container:hover .card-no-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #4c4c4c;
    color: #7a7a7a;
    font-weight: 500;
}

.card-container:hover .card-no-label:hover {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #000000;
    font-weight: 500;
}


.card-no-label.visible {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #000000;
    font-weight: 500;
}

.lm-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    padding: 0px 8px;
}

.lm-title {
    font-size: 16px;
    font-weight: 500;
}

.lm-create-button {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #640FF5;
    font-size: 15px;
    cursor: pointer;
}

.lm-create-button:hover {
    color: #000000;
}

.lm-create-button .icon {
    height: 18px;
    margin-right: 5px;
}

.create-label-container.inline {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.label-input-container.inline {
    margin-bottom: 0px;

}

.label-selector-option.selected {
    background-color: #ededed;
}

@media only screen and (max-width: 768px) {
    .label-selector-search {
        background-color: #ededed;
        border-radius: 10px;
        padding: 10px;
    }

    .label-selector-search-input {
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 500;
    }

    .label-selector-container {
        display: flex;
        flex-direction: column;
        margin: 0px;
        font-size: 16px;
        padding: 0px 10px 10px 10px;
    }

    .label-selector-options {
        display: flex;
        flex-direction: column;
        margin: 0px;
        font-size: 16px;
        padding: 10px;
    }

    .label-selector-option {
        padding: 4px 0px;
        cursor: pointer;
        display: flex;
        font-size: 20px;
        align-items: center;
        margin-bottom: 10px;
    }

    .label-box {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 8px;
    }
}

.button.primary {
    font-size: 13px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    background-color: #640ef5;
    border-radius: 6px;
    padding: 5px 11px;
    display: inline-block;
    border: 1px solid #640ef5;
}

.button.primary:hover {
    background-color: #4200b0;
    border: 1px solid #4200b0;
}

.color-picker-row {
    display: flex;
    background-color: white;
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    column-gap: 5px;
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
}

.color-picker-row .option {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.color-picker-row .option:hover {
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
    border: 1px solid #000000;
}


.option.palette {
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding: 4px;
    cursor: pointer;
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
    /* border: 1px solid #e0e0e0; */
    background-color: #ff58ec;
    color: #ffffff;
}

.palette-container {
    border-left: 1px solid #c5c5c5;
    padding-left: 8px;
    margin-left: 5px;
}

.custom-color-picker-contianer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    box-shadow: 0 0 0 1px rgb(188 188 201 / 18%);
    background: white;
}

.hexColorInput {
    display: block;
    box-sizing: border-box;
    width: 90px;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fcfcfc;
    outline: none;
    font: inherit;
    text-transform: uppercase;
    text-align: center;
    color: #000000;
}

.hex-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-top: 11px;
    font-size: 14px;
    font-weight: 600;
    color: #585858;
}

.custom-color-picker-contianer .header {
    display: flex;
    font-family: 'Inter';
    font-size: 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.picker-back-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #640FF5;
    font-size: 14px;
    cursor: pointer;
}

.picker-back-button .chevron {
    width: 20px;
    height: 20px;
}

.picker-back-button:hover {
    color: #000000;
}

.option .checked {
    width: 20px;
}