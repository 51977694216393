.sidebar-right {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    background-color: #fff;
    /* box-shadow: 0px 1px 3px 0px rgb(18 18 18 / 25%); */
    z-index: 500;
    order: 2;
    border-left: 1px solid rgb(0 0 0 / 10%);
}

.sidebar-toggle-right {
    position: absolute;
    z-index: 1000;
    height: 100vh;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #d1d1d1;
    right: 0;
}

.sidebar-toggle-right:hover {
    color: #000000;
}

.sidebar-toggle-icon {
    height: 30px;
}

.mini-calendar-close {
    height: 20px;
    width: 20px;
}

.mini-calendar-right {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.sidebar-right-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 10px 10px 0px 10px;
}

.mini-calendar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px 0px 14px;
    border-bottom: 1px solid #e9e9e9;
    margin-left: -10px;
    margin-right: -10px;
    /* margin-top: 13px; */
    height: 55px;
    margin-top: -10px;
}

.mini-toolbar-calendar {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.mini-kanban-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px 0px 14px;
    border-bottom: 1px solid #e9e9e9;
    /* margin-left: -10px; */
    /* margin-right: -10px; */
    /* margin-top: 13px; */
    min-height: 55px;
    /* margin-top: -10px; */
    background: white;
}

.mini-calendar-container {
    flex: 1 1 auto;
    width: 100%;
    border-bottom: 0.5px solid var(--fc-border-color, #ddd);
}

.mini-cal-title {
    display: flex;
    align-items: baseline;
    width: 100%;
    flex-direction: row;
}

.mini-cal-date {
    font-weight: 600;
    font-size: 1.1em;
    color: #000000;
    margin-right: 5px;
}

.mini-cal-subdate {
    font-weight: 600;
    font-size: 15px;
    color: #4b4b4b;
    margin-right: 5px;
}

.mini-calendar-nav {
    display: flex;
    align-items: center;
    margin-left: 20px;
    color: #505050;
}

.topbar-button.today-button {
    margin: 0px 5px;
}

.topbar-button.nav-button {
    height: 25px;
    width: 18px;
    cursor: pointer;
    border-radius: 3px;
    padding: 0px;
    box-shadow: none;
    /* padding: 3px 9px; */
}

.topbar-button.nav-button:hover {
    color: #640FF5;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
    --ribbon-width: 4px;
    --ribbon-spacing: 1px;
    --z-index-background: 1;
    --z-index-ribbon: 2;
    --z-index-highlight-overlay: 3;
    --z-index-content: 3;
    --z-index-resize-handle: 5;
    --resize-handle-height: 4px;
    --resize-handle-width: 4px;
    --highlight-overlay-start-opacity: 0.35;
    --highlight-overlay-end-opacity: 0.25;
    --highlight-overlay-start-opacity-dark: 0.04;
    --highlight-overlay-end-opacity-dark: 0.03;
    position: absolute;
    border-color: var(--border-color);
    border-radius: var(--radius-m);
    border-width: 1px;
    padding: var(--chip-padding);
    opacity: 1;
    --background-color: rgb(255, 227, 200);
    --foreground-color-primary: rgb(163, 48, 0);
    --foreground-color-secondary: rgb(174, 69, 23);
    --ribbon-color-primary: rgb(254, 112, 50);
    --ribbon-color-secondary: rgb(254, 112, 50);
    --border-color: transparent;
    box-shadow: 0px 0px 0px 1px var(--material-default);
    text-decoration-color: rgb(174, 69, 23);
}

.fc-theme-standard .fc-scrollgrid {
    border: none;
}

.fc-day-today {
    background: transparent !important;

}

.fc .fc-timegrid-slot-minor {
    border-top-style: hidden;
}

.fc .fc-timegrid-slot-label {
    font-family: Avenir Next;
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: -0.04em;
    text-align: right;
    font-feature-settings: "tnum", "lnum", "ss01", "ss02", "ss03", "ss04";
    color: #717171;
    text-transform: uppercase;
}

.fc-theme-standard td {
    border: .5px solid var(--fc-border-color, #ddd);
    border-right: none;
}

.fc-theme-standard th {
    border: none;
    border-bottom: .5px solid var(--fc-border-color, #ddd);
}

.calendar-header-custom {
    font-size: 14px;
    padding: 3px 0px;
    color: #676767;
    font-weight: 600;
    /* font-family: Avenir Next; */
    display: flex;
    align-items: center;
}

.calendar-header-custom .today {
    color: #1d80ff;
    margin-left: 5px;
    font-size: 11px;
}

.event-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-family: var(--sans-serif-font);
    font-weight: 500;
    padding: 3px 0px;
}

.event-content.small-text {
    font-size: 10px;
    margin-top: -3px;
    font-weight: 600;
}

.event-meta {
    margin-right: 4px;
    row-gap: 2px;
    display: flex;
    flex-direction: column;
    overflow: hidden
}

.event-title {
    /* vertical-align: top; */
    /* display: inline; */
    vertical-align: text-bottom;
    font-family: Inter;
    font-size: 12px;
    line-height: 15px;
}

.event-time {
    font-size: 11px;
    font-weight: 400;
}

.event-title.completed {
    text-decoration: line-through;
}

.event-complete-check {
    flex: 0 0 15px;
    height: 15px;
    color: white;
}

.event-complete-check.small {
    flex: 0 0 10px;
    height: 10px;
    margin-top: 4px;
    color: white;
}

.fc-timegrid-event .fc-event-main {
    padding: 1px 1px 0;
    overflow: hidden;
}

.calendar-settings-bar {
    border-bottom: 1px solid #e9e9e9;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar-settings-icon {
    height: 17px;
    width: 17px;
    color: #444444;
    cursor: pointer;
}

.last-sync-text {
    font-size: 13px;
    color: #7e7e7e;
    font-weight: 500;
}

.calendar-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 4px 9px;
    font-size: 14px;
    /* margin-bottom: 2px; */
}

.calendar-list-item:hover {
    background-color: #efefef;
}

.calendar-list-item-checkbox {
    /* height: 10px; */
    color: white;
    margin-right: 8px;
    padding: 6px;
    border: 2px solid;
    border-radius: 4px;
    cursor: pointer;
}

.calendar-account {
    padding: 5px 10px;
    font-size: 14px;
    color: #646464;
    font-weight: 600;
}

.calendar-settings-title {
    padding: 0px 0px 0px 9px;
    font-size: 15px;
    color: #000000;
    font-weight: 600;
}

.calendar-settings-container {
    padding: 10px 6px;
}

.calendar-settings-content {
    max-height: 450px;
    overflow-y: auto;
}

.context-mmenu-row-icon.logo {
    margin-right: 10px;
    width: 15px;
    height: auto;
}

.last-sync-text {
    display: flex;
    align-items: center;
    color: #b4b4b4;
    cursor: pointer;
}

.last-sync-icon {
    margin-right: 4px;
}

@keyframes spin {
    from {
        transform: scale(1) rotate(0deg);
    }

    to {
        transform: scale(1) rotate(360deg);
    }
}

.last-sync-icon-container {
    padding: 5px;
    border-radius: 50%;
    color: #444444;
    cursor: pointer;
}

.last-sync-icon-container:hover {
    background: #efefef;
}

.mini-toolbar-calendar .last-sync-icon {
    margin-right: 0px;
}

.last-sync-icon.animated {
    animation: spin 1s infinite linear;
}

.last-sync-text:hover {
    color: #000000;
}

.integration-preview {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 20px;
    border-radius: 10px;
}

.integration-image {
    height: 250px;
    border-radius: 10px;
    margin-right: 10px;
}

.integration-title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 25px;
}

.add-calendar-modal {
    padding: 25px;
}

.add-calendar-container.primary {
    padding: 17px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin-top: 10px;
    border: 1px solid #dfdfdf;
    background-color: #ffffff;
    cursor: initial;
}

.add-calendar-container.primary .add-calendar-text {
    font-size: 14px;
    font-weight: 600;
    color: #6c6c6c;
    display: flex;
    align-items: center;
}

.ios-chip {
    background-color: #eaeaea;
    padding: 4px 11px;
    border-radius: 6px;
    font-size: 12px;
    color: #6c6c6c;
    margin-left: 10px;
    -webkit-user-select: none;
    user-select: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.weekview-container {
    height: 100%;
    width: 100%;
    background-color: white;
}

.calendar-header-today {
    background: #6b00ff;
    padding: 1px 4px;
    border-radius: 6px;
    color: #ffffff;
}

.floating-toolbar-calendar {
    position: absolute;
    bottom: 30px;
    right: 40px;
    align-items: end;
    color: #3e3e3e;
    display: flex;
    flex-direction: column;
    z-index: 10000;
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: row;
    column-gap: 7px;
}

.calendar-toolbar {
    padding: 5px 8px;
    background: white;
    border: 1px solid #e4e4e4;
    border-radius: 10px;
    display: flex;
    box-shadow: 0 0 6px 0 #ce646442;
    align-items: center;
    column-gap: 6px;
}

.calendar-settings-inner {
    padding: 10px;
}