.tabbar {
    background-color: #fff;
    box-shadow: rgba(19, 19, 20, .1) 0 -1px 0 0;
    box-sizing: border-box;
    display: flex;
    padding: 20px 0px 20px;
}

.tab {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: #6a686f;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-decoration: none;
    transition: all 150ms ease-in-out 0s;
    will-change: transform;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
    -webkit-user-select: none;
    user-select: none;
}

.tab.active {
    color: #ffffff;
}

.tab-icon {
    height: 25px;
    width: 25px;
}

.tab-text {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.16px;
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}