.floating-toolbar {
  position: absolute;
  bottom: 2em;
  right: 20px;
  display: flex;
  align-items: end;
  color: #3e3e3e;
  display: flex;
  flex-direction: column;
}

.floating-toolbar-content {
  display: flex;
  /* border-radius: 7px; */
  /* background: #FFFFFF; */
  /* box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25); */
  margin-bottom: 10px;
}


.floating-buttons {
}

.floating-button {
  background-color: #ffffff;
  border: 1px solid #d1d1d1;
  color: #535353;
  /* padding: 5px 13px; */
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 10px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  justify-content: center;
  font-weight: 600;
}

.floating-button:hover {
  color: #272EE8;
}

.floating-button.active {
  color: #272EE8;
}

.hc-container {
  color: #2e2e2e;
  background-color: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  display: flex;
  /* flex-direction: column; */
  display: flex;
  align-items: center;
  border-radius: 7px;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
  margin-top: 10px;
  padding: 10px;
  justify-content: space-between;
  font-size: 13px;
}

.floating-bar {
  display: flex;
  align-items: center;
}

.settings-button {
  font-weight: 600;
  cursor: pointer;
  display: inline-flex;
  border-radius: 50%;
  background: #FFFFFF;
  box-shadow: 0px 1px 3px rgba(128, 123, 123, 0.25);
  margin-left: 10px;
  height: 3em;
  width: 3em;
  align-items: center;
  /* vertical-align: middle; */
  justify-content: center;
  padding: 2px;
}

@media only screen and (max-width: 768px) {
  .hc-container {
    color: #2e2e2e;
    background-color: #ffffff;
    font-weight: 500;
    /* flex-direction: column; */
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: #f3f3f3;
    box-shadow: none;
    margin-top: 10px;
    padding: 10px;
    justify-content: space-between;
    font-size: 16px;
    border-radius: 10px;
    margin: 11px;
  }


}

.toggle-right-sidebar-icon {
  height: 25px;
  width: 29px;
  margin-left: 10px;
  color: #404040;
  cursor: pointer;
  /* background-color: #ffffff; */
  padding: 2px;
  border-radius: 4px;
}