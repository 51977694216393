.sidebar-container {
    /* padding: 1em; */
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #fafbfd;
    /* box-shadow: 0px 1px 3px 0px rgb(18 18 18 / 25%); */
    border-right: 1px solid rgb(0 0 0 / 10%);
}

.sidebar-toggle {
    position: absolute;
    z-index: 1000;
    height: 100vh;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #d1d1d1;
}

.sidebar-toggle:hover {
    color: #000000;
}

.sidebar-toggle-icon {
    height: 30px;
}

.sidebar {
    z-index: 500;
    order: 0;
}

.sidebar-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5em;
}


.sidebar-search-container {
    display: flex;
    align-items: center;
    background-color: #efefef;
    border-radius: 6px;
    /* border: 1px solid #c3c3c3; */
    padding: .5em .5em;
    font-size: 14px;
    margin-bottom: 1em;
}

.sidebar-search-icon {
    margin-right: 5px;
    height: 20px;
    color: #818181;
}

.sidebar-search-input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.sidebar-account-container {}

.braindump-column {
    background-color: #f7f7f7;
    height: 96vh;
    border-radius: 6px;
    border: 2px solid #323232;
    color: #000000;
    margin: 1em 0em 1em 1em;
    box-shadow: 7px 0 8px -2px #f1f1f1;
    box-shadow: 0px 2px 4px rgba(109, 109, 109, 0.25);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.braindump-container {
    margin: 15px 15px;
    display: flex;
    flex-direction: column;
    flex: 0 0 250px;
    height: 92vh;
}

.braindump-header {
    margin-bottom: .25em;
    padding: 10px 1em 0em 1em;
}

.braindump-header-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: .5em;
}

.draggable-container.braindump {
    min-height: 400px;
    overflow-y: auto;
    flex: 1 1 auto;
    padding: 0em 1em 1em 1em;
}

.braindump-header-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.collapse-icon {
    height: 20px;
    width: 20px;
    color: #b9b9b9;
    cursor: pointer;
}

.braindump-topbar {
    display: flex;
    align-items: center;
    overflow-x: auto;
    min-height: 55px;
    width: 100%;
    background-color: white;
    /* margin-bottom: 15px; */
    padding: 0px 15px;
    border-bottom: 1px solid #7070701a;
    /* flex: 1 1 54px; */
}

.sidebar-logo {
    height: 50px;
    width: 50px;
}

.collapse-icon:hover {
    color: #000000;
}

@media only screen and (max-width: 768px) {
    .braindump-header-title {
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 10px;
    }

}