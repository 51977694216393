.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #F1F0EE;
  font-family: "Open Sans";
}

/* Ant Overrides */

.ant-popover-inner {
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 4px;
  border: 1px solid rgb(230, 230, 230);
  background-color: rgb(255, 255, 255);
  color: rgb(31, 34, 37);
  text-align: left;
  user-select: none;
  white-space: nowrap;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 10px 20px;
  overflow: hidden;
}

.ant-popover-inner-content {
  border-radius: 8px;
  padding-top: 0px;
  padding-bottom: 4px;

  background-color: rgb(255, 255, 255);
  color: rgb(31, 34, 37);
  text-align: left;
  user-select: none;
  white-space: nowrap;

  overflow: hidden;
}



.app-container {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: "Open Sans";
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  border-radius: 6px;
  background-color: #ffffff;
}

.mobile-container {
  height: 100vh;
}

.mobile-content {}

.mobile-settings-page {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #cbcbcb;
  border: 1px solid #ebebeb;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}

.tabbar-container {
  position: fixed;
  bottom: 0px;
  z-index: 89;
  width: 100%;
}

.floating-buttons-mobile {
  position: fixed;
  bottom: 100px;
  z-index: 89;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  right: 25px;
  -webkit-justify-content: flex-end;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}

.floating-button-mobile {
  background-color: #000000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floating-button-mobile-icon {
  height: 20px;
  width: 20px;
}

.floating-button-mobile.filter {
  margin-right: 10px;
  background-color: #ffffff;
  color: #2e2e2e;
  border: 2px solid #adadad;
}

:root {
  --white: #FFF;
  --gray10: #FCFCFC;
  --gray30: #F7F7F7;
  --gray50: #F2F2F2;
  --gray70: #EDEDED;
  --gray100: #E5E5E5;
  --gray150: #D9D9D9;
  --gray200: #CCCCCC;
  --gray250: #BFBFBF;
  --gray300: #B2B2B2;
  --gray350: #A6A6A6;
  --gray400: #999999;
  --gray450: #8C8C8C;
  --gray500: #808080;
  --gray550: #737373;
  --gray600: #666666;
  --gray650: #595959;
  --gray700: #4D4D4D;
  --gray750: #404040;
  --gray800: #333333;
  --gray850: #262626;
  --gray870: #1F1F1F;
  --gray900: #1A1A1A;
  --black: #000;
  --semantic-red100: #F8C4C7;
  --semantic-red300: #F18392;
  --semantic-red600: #E4253B;
  --semantic-red500: #ED4556;
  --semantic-red700: #CC1E36;
  --semantic-red800: #AD1B2A;
  --semantic-red900: #95040E;
  --semantic-yellow100: #FFE8B2;
  --semantic-yellow500: #F3BD3F;
  --semantic-yellow700: #C79623;
  --semantic-yellow900: #8F6400;
  --semantic-green100: #CFE8C9;
  --semantic-green500: #4DB238;
  --semantic-green700: #3E8131;
  --semantic-green900: #166B06;
  --primary100: #FFCDB3;
  --primary200: #FAAF89;
  --primary300: #FA9161;
  --primary400: #FB6B2D;
  --primary500: #FF4800;
  --primary600: #EF4001;
  --primary700: #DA3B07;
  --primary800: #AA330F;
  --primary900: #8B3118;
  --primary500-gradient: linear-gradient(#FF5C0A, #F54100);
  --secondary-semantic-red: var(--semantic-red700);
  --text-default: var(--gray900);
  --text-default-subdued: var(--gray750);
  --text-body: var(--gray600);
  --text-placeholder: var(--gray300);
  --text-active: var(--black);
  --text-detail: var(--gray400);
  --text-underline: var(--gray250);
  --text-contr-default: var(--gray100);
  --text-contr-highlight-default: var(--white);
  --text-contr-detail: var(--gray400);
  --text-contr-highlight-detail: var(--gray300);
  --text-caret: var(--primary600);
  --text-selection: var(--primary100);
  --text-contr-caret: var(--primary400);
  --text-contr-selection: var(--primary900);
  --object-default: var(--gray400);
  --object-strong: var(--gray800);
  --object-placeholder: var(--gray300);
  --object-highlight: var(--gray900);
  --object-active: var(--black);
  --object-border: var(--gray100);
  --object-highlight-border: var(--gray150);
  --object-highlight-bg: var(--gray70);
  --object-highlight-bg-emphasized: var(--gray100);
  --object-highlight-bg-active: var(--gray100);
  --object-contr-default: var(--gray300);
  --object-contr-highlight: var(--gray50);
  --object-contr-active: var(--white);
  --object-contr-highlight-border: var(--gray650);
  --object-contr-highlight-bg: var(--gray700);
  --object-gradient-bg: linear-gradient(var(--gray10), #FAFAFA);
  --layout-line-divider: #F0F0F0;
  --layout-line-divider-elevated: var(--layout-line-divider);
  --layout-line-divider-base: #EBEBEB;
  --layout-line-grid-v: #F0F0F0;
  --layout-line-grid-h: var(--gray30);
  --layout-line-grid-v-wknd: #F0F0F0;
  --layout-line-grid-h-wknd: #F5F5F5;
  --layout-fill-grid: var(--white);
  --layout-fill-grid-wknd: var(--gray10);
  --material-inset: var(--gray70);
  --material-base: var(--gray30);
  --material-base-dimmed: var(--gray10);
  --material-default: var(--white);
  --material-default-elevated: var(--material-default);
  --material-default-to-base: radial-gradient(50% 66.67% at 50% 33.33%, var(--material-default), var(--material-base));
  --material-contrast: linear-gradient(#383838 0%, #2E2E2E 75%);
  --material-dim: rgba(0, 0, 0, 0.08);
  --material-border-inside: rgba(0, 0, 0, 0.1);
  --material-border-contr-inside: rgba(255, 255, 255, 0.12);
  --elevation-low: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
  --elevation-low-material-border-inside: var(--elevation-low), inset 0px 0px 0px 0.5px var(--material-border-inside);
  --elevation-low-material-border-contr-inside: var(--elevation-low), inset 0px 0px 0px 0.5px var(--material-border-contr-inside);
  --elevation-middle: 0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 16px 32px 0px rgba(0, 0, 0, 0.08);
  --elevation-middle-material-border-inside: var(--elevation-middle), inset 0px 0px 0px 0.5px var(--material-border-inside);
  --elevation-middle-material-border-contr-inside: var(--elevation-middle), inset 0px 0px 0px 0.5px var(--material-border-contr-inside);
  --elevation-high: 0px 2px 4px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.06), 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 16px 32px 0px rgba(0, 0, 0, 0.1), 0px 32px 64px 0px rgba(0, 0, 0, 0.12);
  --elevation-high-material-border-inside: var(--elevation-high), inset 0px 0px 0px 0.5px var(--material-border-inside);
  --elevation-high-material-border-contr-inside: var(--elevation-high), inset 0px 0px 0px 0.5px var(--material-border-contr-inside);
  --highlight-light: inset 0px -0.5px 0px 0px rgba(0, 0, 0, 0.1), inset 0px 0.5px 0px 0px rgba(255, 255, 255, 0.25);
  --highlight-focus: 0px 0px 0px 1px var(--primary600), 0px 0px 0px 4px var(--primary100);
  --highlight-contr-focus: 0px 0px 0px 1px var(--primary400), 0px 0px 0px 4px var(--primary900);
  --highlight-cursor: 0px 1px 2.6px 0px rgba(0, 0, 0, 0.32);
  --grid-selection-fill-opacity: 0.125;
  --grid-selection-border-opacity: 0.25;
  --ghost-opacity: 0.5;
  --hidden-opacity: 0.25;
  --dimmed-opacity: var(--hidden-opacity);
  --disabled-opacity: 0.33;
  --disabled-destructive-opacity: 0.5;
  --icon-secondary-opacity: 0.5;
  --date-flash-indicator-opacity: 0.33;
  --radius-s: 2px;
  --radius-m: 4px;
  --radius-l: 8px;
  --context-panel-width: 256px;
  --time-column-padding-right: 6px;
  --time-column-left-gutter-width: 20px;
  --chip-min-height: 10px;
  --chip-ribbon-width: 8px;
  --chip-ribbon-gap: 3px;
  --chip-title-font-size: 11px;
  --chip-title-line-height: 13px;
  --chip-time-margin-top: 2px;
  --chip-time-font-size: 9px;
  --chip-time-line-height: 11px;
  --chip-margin-bottom: 3px;
  --chip-margin-right: 13px;
  --chip-padding-top: 2px;
  --chip-padding-bottom: 1px;
  --chip-padding: var(--chip-padding-top) 4px var(--chip-padding-bottom) 8px;
  --time-indicator: var(--black);
  --time-indicator-bg: var(--white);
  --input-border: inset 0px 0px 0px 1px var(--object-border);
  --input-error-border: inset 0px 0px 0px 1px var(--semantic-red500);
  --input-highlight-border: inset 0px 0px 0px 1px var(--object-highlight-border);
  --input-highlight-bg: var(--object-highlight-bg);
  --input-font-size: 11px;
  --input-line-height: 16px;
  --input-letter-spacing: 0.01em;
  --kbs-bg: rgba(0, 0, 0, 0.07);
  --kbs: rgba(0, 0, 0, 0.45);
  --contr-kbs-bg: rgba(255, 255, 255, 0.2);
  --contr-kbs: rgba(255, 255, 255, 0.8);
  --sans-serif-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, Arial, sans-serif;
  --compact-font: SF Compact, ".SF Compact", SF Compact Text, ".SF Compact Text", var(--sans-serif-font);
  --monospace-font: ui-monospace, SF Mono, SFMono-Regular, Menlo, Lucida Console, Courier New, Courier, monospace;
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
  --ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
  --ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  --animation-duration-fast: 0.1s;
  --animation-duration-default: 0.175s;
  --animation-duration-long: 0.275s;
  --animation-duration-xlong: 0.875s;
  --animation-duration-context-panel: 0.3s;
  --animation-duration-pulsate: 1.5s;
  --animation-duration-toast-fade-out: 2s;
  --animation-duration-date-flash: 3s;
  --animation-easing-default: var(--ease-out-quad);
  --animation-delay-default: 0.05s;
  --animation-scale-initial-default: 0.95;
  --animation-scale-exit-default: 0.975;
  --z-index-grid-main: 1;
  --z-index-grid-foreground: 200;
  --z-index-grid-hidden-zone: 301;
  --z-index-grid-cursor-indicator: 302;
  --z-index-grid-cursor: 303;
  --z-index-grid-time-indicator: 304;
  --z-index-grid-selected-item: 305;
  --z-index-grid-col-header: 306;
  --z-index-grid-period-length-selector: 307;
  --z-index-grid-all-day: 308;
  --z-index-grid-all-day-cursor-indicator: 309;
  --z-index-grid-all-day-cursor: 310;
  --z-index-grid-time-col: 311;
  --z-index-grid-time-col-header: 312;
  --z-index-grid-bounding-box-selection: 313;
  --z-index-calendar-header: 400;
  --z-index-popover: 401;
  --z-index-toast: 402;
  --z-index-modal: 403;
  --z-index-tooltip: 404;
  --z-index-traffic-light: 405;
  --notifications-window-padding: 16px;
  --notifications-window-padding-bottom: 24px;
  --notification-width: 400px;
  --notification-height: 68px;
  --notification-spacing: 8px;
  --notification-radius: 10px;
  --notification-button-radius: 5px;
  --notification-dismiss-duration-safe: 1s;
}

.ant-popover .ant-popover-inner {
  padding: 0px;
}

.ant-modal .ant-modal-content {
  padding: 0px;
}

[class^="ant-popover"],
[class*=" ant-popover"] {
  font-size: inherit;
  font-family: inherit;
}