.subtasks {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.subtask {
    display: flex;
    align-items: initial;
    background-color: #ffffff;
    padding: 0px 5px;
    padding-bottom: 7px;
    padding-top: 6px;
    margin-left: -28px;
    border-radius: 9px;
    column-gap: 8px;
}

.subtask.inline-mode {
    margin-left: 0px;
}

.subtask.preview {
    border: 2px solid #0071ffa6;
    border-radius: 6px;
}


.subtask-check-icon {

    height: 18px;
    width: 18px;
}

.modal-subcontent {
    width: 100%;
    margin-bottom: 13px;
    padding: 2px;
}

.subtask-description {
    -webkit-appearance: none;
    appearance: none;
    background-color: initial;
    background-image: none;
    border-radius: 3px;
    border-style: none;
    box-shadow: none;
    color: #172b4d;
    display: block;
    line-height: 20px;
    font-family: "Open Sans";
    outline: none;
    overflow: hidden;
    overflow-wrap: break-word;
    resize: none;
    transition-duration: 85ms;
    transition-property: background-color, border-color, box-shadow;
    transition-timing-function: ease;
    padding: 0px;
    /* border: 2px solid transparent; */
    flex: 1 1 auto;
    margin-top: -1px;

}

.add-subtask-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    color: #bababa;
    font-size: 14px;
    margin-top: 6px;
    font-family: 'Inter';
}

.add-subtask-button.inline-mode {
    margin-top: 4px;
    padding: 0px 6px;
    font-size: 13px;
}

.add-subtask-icon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

.add-subtask-button:hover {
    color: #1d80ff;
}

.subtask:hover {
    background: #f7f7f7;
}

.subtask .left {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.subtask:hover .dragger {
    visibility: visible;
}

.subtask.inline-mode .dragger {
    visibility: visible;
}

.subtask.inline-mode .subtask-dropdown {
    visibility: visible;
}

.subtask:focus-within .dragger {
    visibility: visible;
}

.subtask:focus-within .subtask-dropdown {
    visibility: visible;
}

.subtask:focus-within {
    background: #f7f7f7;
}

.dragger {
    height: 17px;
    width: 17px;
    color: #cdcdcd;
    cursor: grab;
    visibility: hidden;
}

.subtask-dropdown {
    height: 19px;
    width: 17px;
    color: #8e8e8e;
    cursor: pointer;
    visibility: hidden;

}

.subtask:hover .subtask-dropdown {
    visibility: visible;
}

.subtask.complete {
    opacity: .5;
}

.subtask.complete:hover {
    opacity: 1;
}

.subtasks-container {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
}